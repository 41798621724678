import axios from "../index";

// 获取录单影像资料列表
export function getTypeInOrderInfoAccessoryFind(data) {
  return axios.get(
    "/bapi/managerBusiness/getTypeInOrderInfoAccessoryFind",
    data
  );
}

// 上传图片
export function uploadFile(data) {
  return axios.post("/bapi/managerBusiness/uploadFile", data);
}

// 暂存录单信息
export function cacheTypeInOrderInfo(data) {
  return axios.post("/bapi/managerBusiness/cacheTypeInOrderInfo", data);
}
