<template>
  <div class="app-conter">
    <!-- 顶部icon -->
    <div class="iconbox">
      <div class="iconbox-xx"></div>
      <div class="iconbox-box">
        <img
          style="width: 45px; height: 45px"
          src="../../assets/img/dkxx.png"
          alt=""
        />
        <p>贷款信息</p>
      </div>
      <div class="iconbox-box">
        <img
          style="width: 45px; height: 45px"
          src="../../assets/img/jcxx.png"
          alt=""
        />
        <p>基础信息</p>
      </div>
      <div class="iconbox-box">
        <img
          style="width: 45px; height: 45px"
          src="../../assets/img/dywxx.png"
          alt=""
        />
        <p>抵押物信息</p>
      </div>
      <div class="iconbox-box">
        <img
          style="width: 45px; height: 45px"
          src="../../assets/img/zlsc.png"
          alt=""
        />
        <p>资料上传</p>
      </div>
    </div>
    <div class="main">
      <!-- 贷款信息 -->
      <div>
        <div class="titlebox">
          <div class="title-back"></div>
          <p class="title">贷款信息</p>
        </div>
        <van-form @submit="onSubmit" ref="orderBo">
          <van-field
            v-model="orderBo.applyAmount"
            label="申请金额(万元)"
            label-width="120px"
            placeholder="请输入"
            input-align="right"
            maxlength="4"
            type="number"
            required
            colon
          />
          <van-field
            v-model="orderBo.mortgageType"
            label="抵押类型"
            label-width="120px"
            placeholder="请选择"
            input-align="right"
            right-icon="arrow"
            required
            readonly
            colon
            @click="mortgageTypePopup = true"
          >
          </van-field>
          <van-popup v-model="mortgageTypePopup" position="bottom">
            <van-picker
              show-toolbar
              :columns="mortgageTypeColumns"
              @confirm="mortgageTypeOnConfirm"
              @cancel="mortgageTypePopup = false"
            />
          </van-popup>
          <van-field
            v-model="orderBo.applyPeriods"
            label="申请期限(月)"
            label-width="120px"
            placeholder="请输入"
            input-align="right"
            maxlength="4"
            type="number"
            required
            colon
          />
          <van-field
            v-model="orderBo.moneyUseFor"
            label="借款用途"
            label-width="120px"
            placeholder="请输入"
            input-align="right"
            maxlength="20"
            required
            colon
          />
        </van-form>
      </div>
      <!-- 基础信息 -->
      <div>
        <div class="titlebox">
          <div class="title-back"></div>
          <p class="title">基础信息</p>
        </div>
        <van-form @submit="onSubmit" ref="customersBo">
          <van-field
            v-model="customersBo.customerName"
            maxlength="12"
            label="借款人"
            label-width="120px"
            placeholder="请输入姓名"
            input-align="right"
            required
            @blur="inputChange($event)"
          />
          <van-field
            v-model="customersBo.contactPhone"
            type="number"
            maxlength="11"
            label="手机号"
            label-width="120px"
            placeholder="请输入手机号"
            input-align="right"
            required
          />
          <van-field
            v-model="customersBo.idCard"
            maxlength="18"
            label="身份证号"
            label-width="120px"
            placeholder="请输入身份证号"
            input-align="right"
            required
          />
          <van-field
            name="switch"
            label="证件有效期"
            label-width="120px"
            input-align="right"
          >
            <template #input>
              <van-switch
                v-model="customersBo.idCardIndateType"
                :inactive-value="1"
                :active-value="2"
                size="20"
                active-color="#EFC84F"
                inactive-color="#dcdee0"
              />
              <span v-if="customersBo.idCardIndateType == 1">短期</span>
              <span v-if="customersBo.idCardIndateType == 2">长期</span>
            </template>
          </van-field>
          <van-field
            v-if="customersBo.idCardIndateType == 1"
            readonly
            clickable
            name="idEndTime"
            :value="customersBo.idEndTime"
            label-width="120px"
            input-align="right"
            right-icon="arrow"
            label="证件时间选择"
            placeholder="请选择"
            @click="currentDate = true"
          />
          <van-popup v-model="currentDate" position="bottom">
            <van-datetime-picker
              type="date"
              :columns-order="['year', 'month', 'day']"
              title="选择年月日"
              @confirm="onConfirm4"
              @cancel="currentDate = false"
              :max-date="new Date(3000, 10, 1)"
            />
          </van-popup>
          <van-field
            v-model="customersBo.gender"
            label="性别"
            placeholder="请选择"
            label-width="120px"
            input-align="right"
            right-icon="arrow"
            readonly
            @click="genderPopup = true"
          >
          </van-field>
          <van-popup v-model="genderPopup" position="bottom">
            <van-picker
              show-toolbar
              :columns="genderColumns"
              @confirm="genderOnConfirm"
              @cancel="genderPopup = false"
            />
          </van-popup>
          <van-field
            v-model="customersBo.education"
            label="学历"
            label-width="120px"
            placeholder="请选择"
            input-align="right"
            right-icon="arrow"
            readonly
            @click="educationPopup = true"
          >
          </van-field>
          <van-popup v-model="educationPopup" position="bottom">
            <van-picker
              show-toolbar
              :columns="educationColumns"
              @confirm="educationOnConfirm"
              @cancel="educationPopup = false"
            />
          </van-popup>
          <van-field
            v-model="customersBo.maritalState"
            placeholder="请选择"
            right-icon="arrow"
            @click="maritalStatePopup = true"
            label="婚姻状况"
            label-width="120px"
            input-align="right"
            readonly
          />
          <van-popup v-model="maritalStatePopup" position="bottom">
            <van-picker
              show-toolbar
              :columns="maritalStateColumns"
              @confirm="maritalStateOnConfirm"
              @cancel="maritalStatePopup = false"
            />
          </van-popup>
          <van-field
            v-model="customersBo.childrenState"
            placeholder="请选择"
            right-icon="arrow"
            @click="childrenStatePopup = true"
            label="子女数（个）"
            label-width="120px"
            input-align="right"
            readonly
          >
          </van-field>
          <van-popup v-model="childrenStatePopup" position="bottom">
            <van-picker
              show-toolbar
              :columns="childrenStateColumns"
              @confirm="childrenStateOnConfirm"
              @cancel="childrenStatePopup = false"
            />
          </van-popup>
          <van-field
            v-model="customersBo.familyNumber"
            placeholder="请选择"
            right-icon="arrow"
            @click="familyNumberPopup = true"
            label="家庭人数（个）"
            label-width="120px"
            input-align="right"
            readonly
          >
          </van-field>
          <van-popup v-model="familyNumberPopup" position="bottom">
            <van-picker
              show-toolbar
              :columns="familyNumberColumns"
              @confirm="familyNumberOnConfirm"
              @cancel="familyNumberPopup = false"
            />
          </van-popup>

          <van-field
            v-model="customersBo.residentialProperties"
            placeholder="请选择"
            right-icon="arrow"
            @click="residentialPropertiesPopup = true"
            label="居住性质"
            label-width="120px"
            input-align="right"
            readonly
          >
          </van-field>
          <van-popup v-model="residentialPropertiesPopup" position="bottom">
            <van-picker
              show-toolbar
              :columns="residentialPropertiesColumns"
              @confirm="residentialPropertiesOnConfirm"
              @cancel="residentialPropertiesPopup = false"
            />
          </van-popup>

          <van-field
            readonly
            clickable
            label-width="120px"
            input-align="right"
            right-icon="arrow"
            name="area"
            :value="customersBo.permanentResidenceName"
            label="户籍地址"
            placeholder="请选择省、市、区"
            @click="showArea1 = true"
          />
          <van-popup v-model="showArea1" position="bottom">
            <van-area
              :area-list="areaList"
              @confirm="onConfirm1"
              @cancel="showArea1 = false"
              value="450000"
            />
          </van-popup>
          <van-field
            v-model="customersBo.permanentResidenceAddress"
            maxlength="50"
            autosize
            type="textarea"
            label="详细地址"
            label-width="120px"
            placeholder="请输入详细地址"
            input-align="right"
          />
          <van-field
            readonly
            clickable
            label-width="120px"
            input-align="right"
            right-icon="arrow"
            name="area"
            :value="customersBo.residenceName"
            label="现居住地址"
            placeholder="请选择省、市、区"
            @click="showArea2 = true"
          />
          <van-popup v-model="showArea2" position="bottom">
            <van-area
              :area-list="areaList"
              @confirm="onConfirm2"
              @cancel="showArea2 = false"
              value="450000"
            />
          </van-popup>
          <van-field
            v-model="customersBo.residenceAddress"
            maxlength="50"
            autosize
            type="textarea"
            label="详细地址"
            label-width="120px"
            placeholder="请输入详细地址"
            input-align="right"
          />
        </van-form>
      </div>
      <!-- 抵押物信息 -->
      <div>
        <div class="titlebox">
          <div class="title-back"></div>
          <p class="title">抵押物信息</p>
        </div>
        <van-form @submit="onSubmit" ref="orderPawnBO">
          <van-field
            v-model="orderPawnBO.propertyOwner"
            maxlength="12"
            label="产权人"
            label-width="120"
            input-align="right"
            placeholder="请输入"
          />
          <van-field
            v-model="orderPawnBO.realEstateNumber"
            maxlength="50"
            label="不动产权证号"
            label-width="120"
            input-align="right"
            placeholder="请输入"
          />
          <van-field
            readonly
            clickable
            label-width="120px"
            input-align="right"
            right-icon="arrow"
            name="area"
            :value="orderPawnBO.houseSiteName"
            label="房产坐落"
            placeholder="请选择省、市、区"
            @click="showArea3 = true"
          />
          <van-popup v-model="showArea3" position="bottom">
            <van-area
              :area-list="areaList"
              @confirm="onConfirm3"
              @cancel="showArea3 = false"
              value="450000"
            />
          </van-popup>
          <van-field
            v-model="orderPawnBO.houseDetailedAddress"
            maxlength="50"
            autosize
            type="textarea"
            label="详细地址"
            label-width="120px"
            input-align="right"
            placeholder="请输入"
          />
          <van-field
            label="房屋类型"
            label-width="120px"
            input-align="right"
            right-icon="arrow"
            placeholder="请选择"
            @click="houseTypePopup = true"
            v-model="orderPawnBO.houseType"
            readonly
          >
          </van-field>
          <van-popup v-model="houseTypePopup" position="bottom">
            <van-picker
              show-toolbar
              :columns="houseTypeColumns"
              @confirm="houseTypeOnConfirm"
              @cancel="houseTypePopup = false"
            />
          </van-popup>

          <van-field
            label="土地性质"
            label-width="120px"
            input-align="right"
            right-icon="arrow"
            placeholder="请选择"
            @click="houseLandStatusPopup = true"
            v-model="orderPawnBO.houseLandStatus"
            readonly
          >
          </van-field>
          <van-popup v-model="houseLandStatusPopup" position="bottom">
            <van-picker
              show-toolbar
              :columns="houseLandStatusColumns"
              @confirm="houseLandStatusOnConfirm"
              @cancel="houseLandStatusPopup = false"
            />
          </van-popup>

          <van-field
            maxlength="7"
            type="number"
            label-width="120px"
            label="建筑面积"
            input-align="right"
            v-model="orderPawnBO.houseScale"
            placeholder="请输入"
            @input="houseScaleinput"
          />
          <van-field
            maxlength="7"
            type="number"
            label-width="120px"
            label="土地使用面积"
            input-align="right"
            v-model="orderPawnBO.landUseArea"
            placeholder="请输入"
            @input="landUseAreainput"
          />
          <van-field
            label="共有情况"
            label-width="120px"
            input-align="right"
            right-icon="arrow"
            placeholder="请选择"
            @click="inCommonSituationPopup = true"
            v-model="orderPawnBO.inCommonSituation"
            readonly
          >
          </van-field>
          <van-popup v-model="inCommonSituationPopup" position="bottom">
            <van-picker
              show-toolbar
              :columns="inCommonSituationColumns"
              @confirm="inCommonSituationOnConfirm"
              @cancel="inCommonSituationPopup = false"
            />
          </van-popup>
        </van-form>
      </div>
      <!-- 资料上传 -->
      <div>
        <div class="titlebox">
          <div class="title-back"></div>
          <p class="title">资料上传</p>
        </div>
        <van-form @submit="onSubmit" ref="orderAccessoryVos">
          <div
            v-for="(item, index) in finddata"
            :key="index"
            style="margin-top: 20px; background: #fff"
          >
            <van-field
              :label="item.accessoryTypeName"
              readonly
              label-width="130px"
            />
            <van-uploader
              style="margin: 5px"
              multiple
              max-count="100"
              :name="item.accessoryTypeName"
              v-model="fileList[index]"
              :after-read="afterRead"
              @delete="deletedata"
            />
          </div>
        </van-form>
      </div>
      <!-- 提交申请 -->
      <div style="margin: 16px">
        <van-button
          color="linear-gradient(to right, #BCA675, #A78A48)"
          round
          block
          type="info"
          native-type="submit"
          @click="Submitbtn"
          >提交申请</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { getGzhCode } from "../../api/login/login";
import {
  uploadFile,
  getTypeInOrderInfoAccessoryFind,
  cacheTypeInOrderInfo,
} from "../../api/IncomingParts/IncomingParts";
import { typeInOrderInfo } from "../../api/order/order";
import { getDate } from "@/utils/LocalStorage";
export default {
  data() {
    return {
      currentDate: false,
      areaList,
      showArea1: false,
      showArea2: false,
      showArea3: false,
      orderId: "",
      orderNumber: "",
      // 图片列表
      fileList: [],
      // 全部信息
      submitTypeInOrderInfoBo: {},
      // 贷款信息
      orderBo: {
        applyAmount: "",
        mortgageType: "",
        applyPeriods: "",
        moneyUseFor: "",
      },
      // 基础信息
      customersBo: {
        idCardIndateType: 1,
        customerName: null,
        contactPhone: null,
        idCard: null,
        gender: "男",
        education: null,
        maritalState: null,
        residentialProperties: null,
        permanentResidenceAddress: "",
        permanentResidenceProvinceCode: "",
        permanentResidenceCityCode: "",
        permanentResidenceDistrictCode: "",
        residenceAddress: "",
        residenceProvinceCode: "",
        residenceCityCode: "",
        residenceDistrictCode: "",
      },
      // 抵押物信息
      orderPawnBO: {
        propertyOwner: null,
        realEstateNumber: null,
        houseDetailedAddress: "",
        houseSiteProvinceCode: "",
        houseSiteCityCode: "",
        houseSiteDistrictCode: "",
        houseType: null,
        houseLandStatus: null,
        houseScale: null,
        inCommonSituation: null,
      },
      // 影像资料信息
      orderAccessoryVos: [],
      // 资料上传列表
      finddata: [],
      // 抵押类型选择器
      mortgageTypePopup: false,
      mortgageTypeColumns: ["一抵", "二抵", "三抵"],
      // 性别选择器
      genderPopup: false,
      genderColumns: ["男", "女"],
      // 学历选择器
      educationPopup: false,
      educationColumns: [
        "初中及以下",
        "高中",
        "中专",
        "大专",
        "本科",
        "硕士",
        "博士及以上",
        "其他",
      ],
      // 婚姻状况选择器
      maritalStatePopup: false,
      maritalStateColumns: ["未婚", "已婚", "离异", "复婚", "丧偶", "其他"],
      // 子女个数选择器
      childrenStatePopup: false,
      childrenStateColumns: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
      ],
      // 家庭人数选择器
      familyNumberPopup: false,
      familyNumberColumns: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      // 居住性质选择器
      residentialPropertiesPopup: false,
      residentialPropertiesColumns: [
        "商品房",
        "安置房",
        "自建房",
        "亲属住房",
        "公司宿舍",
        "存量房",
        "其他",
      ],
      // 房屋类型选择器
      houseTypePopup: false,
      houseTypeColumns: [
        "标准商品房",
        "房改房",
        "产前安置房",
        "经济适用房",
        "政策性住房",
        "限价商品房",
        "公寓",
        "商铺",
        "办公楼",
        "自建房",
        "两限房",
        "新工房",
        "别墅",
        "福利房",
        "里弄",
        "保障性住房",
        "商住两用",
        "其他",
      ],
      // 土地性质选择器
      houseLandStatusPopup: false,
      houseLandStatusColumns: ["出让", "划拨", "转让", "其他"],
      // 共有情况选择器
      inCommonSituationPopup: false,
      inCommonSituationColumns: ["单独所有", "共同所有", "按份共有"],
    };
  },
  methods: {
    inputChange(e) {
      const o = e.target;
      if (o.value) {
        if (!/^[\u4e00-\u9fa5]{0,}$/.test(o.value)) {
          this.$toast("请填写中文");
        }
      }
    },
    // 图片上传完毕
    afterRead(file, e) {
      if (file.length >= 1) {
        for (const _key in file) {
          let obj = {};
          for (const key in this.finddata) {
            if (this.finddata[key].accessoryTypeName == e.name) {
              obj.accessoryTypeId = this.finddata[key].id;
            }
          }
          let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", file[_key].file); //接口需要传的参数
          uploadFile(formData).then((res) => {
            // console.log(res.data.fileUrl);
            obj.accessoryUrl = res.data.fileUrl;
            obj.fileName = e.name + "" + new Date().getTime();
            obj.typeName = e.name;
            this.orderAccessoryVos.push(obj);
          });
        }
      } else {
        let obj = {};
        for (const key in this.finddata) {
          if (this.finddata[key].accessoryTypeName == e.name) {
            obj.accessoryTypeId = this.finddata[key].id;
          }
        }
        let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
        formData.append("file", file.file); //接口需要传的参数
        uploadFile(formData).then((res) => {
          // console.log(res.data.fileUrl);
          obj.accessoryUrl = res.data.fileUrl;
          obj.fileName = e.name + "" + new Date().getTime();
          obj.typeName = e.name;
          this.orderAccessoryVos.push(obj);
        });
      }
      console.log(this.orderAccessoryVos);
    },
    // 图片删除
    deletedata(file, e) {
      let arr = [];
      for (let i = 0; i < this.orderAccessoryVos.length; i++) {
        if (this.orderAccessoryVos[i].typeName == e.name) {
          arr.push(this.orderAccessoryVos[i]);
        }
      }
      let index = this.orderAccessoryVos.indexOf(arr[e.index]);
      this.orderAccessoryVos.splice(index, 1);
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    // 建筑面积保留2位小数
    houseScaleinput(value) {
      this.orderPawnBO.houseScale = value.match(/^\d*(\.?\d{0,2})/g)[0];
    },
    // 土地使用面积保留2位小数
    landUseAreainput(value) {
      this.orderPawnBO.landUseArea = value.match(/^\d*(\.?\d{0,2})/g)[0];
    },
    onSubmit() {},
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
    },
    // 抵押类型选择器
    mortgageTypeOnConfirm(value) {
      this.orderBo.mortgageType = value;
      this.mortgageTypePopup = false;
    },
    // 性别选择器
    genderOnConfirm(value) {
      this.customersBo.gender = value;
      this.genderPopup = false;
    },
    // 学历选择器
    educationOnConfirm(value) {
      this.customersBo.education = value;
      this.educationPopup = false;
    },
    // 婚姻状况选择器
    maritalStateOnConfirm(value) {
      this.customersBo.maritalState = value;
      this.maritalStatePopup = false;
    },
    // 子女数选择器
    childrenStateOnConfirm(value) {
      this.customersBo.childrenState = value;
      this.childrenStatePopup = false;
    },
    // 家庭人数选择器
    familyNumberOnConfirm(value) {
      this.customersBo.familyNumber = value;
      this.familyNumberPopup = false;
    },
    // 居住性质选择器
    residentialPropertiesOnConfirm(value) {
      this.customersBo.residentialProperties = value;
      this.residentialPropertiesPopup = false;
    },
    // 房屋类型选择器
    houseTypeOnConfirm(value) {
      this.orderPawnBO.houseType = value;
      this.houseTypePopup = false;
    },
    // 土地性质选择器
    houseLandStatusOnConfirm(value) {
      this.orderPawnBO.houseLandStatus = value;
      this.houseLandStatusPopup = false;
    },
    // 共有情况选择器
    inCommonSituationOnConfirm(value) {
      this.orderPawnBO.inCommonSituation = value;
      this.inCommonSituationPopup = false;
    },
    // 户籍地址选择器
    onConfirm1(values) {
      this.customersBo.permanentResidenceProvinceCode = values[0].code;
      this.customersBo.permanentResidenceCityCode = values[1].code;
      this.customersBo.permanentResidenceDistrictCode = values[2].code;
      this.customersBo.permanentResidenceName = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("");
      this.showArea1 = false;
    },
    // 现居住地址选择器
    onConfirm2(values) {
      this.customersBo.residenceProvinceCode = values[0].code;
      this.customersBo.residenceCityCode = values[1].code;
      this.customersBo.residenceDistrictCode = values[2].code;
      this.customersBo.residenceName = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("");
      this.showArea2 = false;
    },
    // 房产坐落选择器
    onConfirm3(values) {
      this.orderPawnBO.houseSiteProvinceCode = values[0].code;
      this.orderPawnBO.houseSiteCityCode = values[1].code;
      this.orderPawnBO.houseSiteDistrictCode = values[2].code;
      this.orderPawnBO.houseSiteName = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("");
      this.showArea3 = false;
    },
    // 证件有效期选择器
    onConfirm4(value) {
      console.log(value);

      this.customersBo.idEndTime = `${value.getFullYear()}-${
        value.getMonth() + 1 >= 10
          ? value.getMonth() + 1
          : "0" + (value.getMonth() + 1)
      }-${value.getDate() >= 10 ? value.getDate() : "0" + value.getDate()}`;
      this.currentDate = false;
    },

    // 翻译性别
    fygender() {
      let yl = this.customersBo.gender;
      if (yl == "男") {
        this.submitTypeInOrderInfoBo.customersBo.gender = "MALE";
      } else if (yl == "女") {
        this.submitTypeInOrderInfoBo.customersBo.gender = "FEMALE";
      }

      if (yl == "MALE") {
        this.customersBo.gender = "男";
      } else if (yl == "FEMALE") {
        this.customersBo.gender = "女";
      }
    },
    // 翻译学历
    fyeducation() {
      let yl = this.customersBo.education;
      if (yl == "初中及以下") {
        this.submitTypeInOrderInfoBo.customersBo.education = "CZ";
      } else if (yl == "中专") {
        this.submitTypeInOrderInfoBo.customersBo.education = "ZZ";
      } else if (yl == "高中") {
        this.submitTypeInOrderInfoBo.customersBo.education = "GZ";
      } else if (yl == "大专") {
        this.submitTypeInOrderInfoBo.customersBo.education = "DZ";
      } else if (yl == "本科") {
        this.submitTypeInOrderInfoBo.customersBo.education = "BK";
      } else if (yl == "硕士") {
        this.submitTypeInOrderInfoBo.customersBo.education = "SS";
      } else if (yl == "博士及以上") {
        this.submitTypeInOrderInfoBo.customersBo.education = "BS";
      } else if (yl == "其他") {
        this.submitTypeInOrderInfoBo.customersBo.education = "QT";
      }
      if (yl == "CZ") {
        this.customersBo.education = "初中及以下";
      } else if (yl == "ZZ") {
        this.customersBo.education = "中专";
      } else if (yl == "GZ") {
        this.customersBo.education = "高中";
      } else if (yl == "DZ") {
        this.customersBo.education = "大专";
      } else if (yl == "BK") {
        this.customersBo.education = "本科";
      } else if (yl == "SS") {
        this.customersBo.education = "硕士";
      } else if (yl == "BS") {
        this.customersBo.education = "博士及以上";
      } else if (yl == "QT") {
        this.customersBo.education = "其他";
      }
    },
    // 翻译婚姻状态
    fymaritalState() {
      let yl = this.customersBo.maritalState;
      if (yl == "未婚") {
        this.submitTypeInOrderInfoBo.customersBo.maritalState = "WH";
      } else if (yl == "已婚") {
        this.submitTypeInOrderInfoBo.customersBo.maritalState = "YH";
      } else if (yl == "离异") {
        this.submitTypeInOrderInfoBo.customersBo.maritalState = "LY";
      } else if (yl == "复婚") {
        this.submitTypeInOrderInfoBo.customersBo.maritalState = "FH";
      } else if (yl == "丧偶") {
        this.submitTypeInOrderInfoBo.customersBo.maritalState = "SO";
      } else if (yl == "其他") {
        this.submitTypeInOrderInfoBo.customersBo.maritalState = "QT";
      }

      if (yl == "WH") {
        this.customersBo.maritalState = "未婚";
      } else if (yl == "YH") {
        this.customersBo.maritalState = "已婚";
      } else if (yl == "LY") {
        this.customersBo.maritalState = "离异";
      } else if (yl == "FH") {
        this.customersBo.maritalState = "复婚";
      } else if (yl == "SO") {
        this.customersBo.maritalState = "丧偶";
      } else if (yl == "QT") {
        this.customersBo.maritalState = "其他";
      }
    },
    // 翻译居住性质
    fyresidentialProperties() {
      let yl = this.customersBo.residentialProperties;
      if (yl == "商品房") {
        this.submitTypeInOrderInfoBo.customersBo.residentialProperties = "SPF";
      } else if (yl == "安置房") {
        this.submitTypeInOrderInfoBo.customersBo.residentialProperties = "AZF";
      } else if (yl == "自建房") {
        this.submitTypeInOrderInfoBo.customersBo.residentialProperties = "ZJF";
      } else if (yl == "亲属住房") {
        this.submitTypeInOrderInfoBo.customersBo.residentialProperties =
          "QS_ZF";
      } else if (yl == "公司宿舍") {
        this.submitTypeInOrderInfoBo.customersBo.residentialProperties =
          "GS_SS";
      } else if (yl == "存量房") {
        this.submitTypeInOrderInfoBo.customersBo.residentialProperties = "CLF";
      } else if (yl == "其他") {
        this.submitTypeInOrderInfoBo.customersBo.residentialProperties = "QT";
      }

      if (yl == "SPF") {
        this.customersBo.residentialProperties = "商品房";
      } else if (yl == "AZF") {
        this.customersBo.residentialProperties = "安置房";
      } else if (yl == "ZJF") {
        this.customersBo.residentialProperties = "自建房";
      } else if (yl == "QS_ZF") {
        this.customersBo.residentialProperties = "亲属住房";
      } else if (yl == "GS_SS") {
        this.customersBo.residentialProperties = "公司宿舍";
      } else if (yl == "CLF") {
        this.customersBo.residentialProperties = "存量房";
      } else if (yl == "QT") {
        this.customersBo.residentialProperties = "其他";
      }
    },
    // 翻译房屋类型
    fyhouseType() {
      let yl = this.orderPawnBO.houseType;
      if (yl == "标准商品房") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "BZSPF";
      } else if (yl == "房改房") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "FGF";
      } else if (yl == "产前安置房") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "CQAZF";
      } else if (yl == "经济适用房") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "JJSYF";
      } else if (yl == "政策性住房") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "ZCXZF";
      } else if (yl == "限价商品房") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "XJSPF";
      } else if (yl == "公寓") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "GY";
      } else if (yl == "商铺") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "SP";
      } else if (yl == "办公楼") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "BGL";
      } else if (yl == "自建房") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "ZJF";
      } else if (yl == "两限房") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "LXF";
      } else if (yl == "新工房") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "XGF";
      } else if (yl == "别墅") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "BS";
      } else if (yl == "福利房") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "FLF";
      } else if (yl == "里弄") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "LL";
      } else if (yl == "保障性住房") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "BZXZF";
      } else if (yl == "商住两用") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "SZLY";
      } else if (yl == "其他") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType = "QT";
      }

      if (yl == "BZSPF") {
        this.orderPawnBO.houseType = "标准商品房";
      } else if (yl == "FGF") {
        this.orderPawnBO.houseType = "房改房";
      } else if (yl == "CQAZF") {
        this.orderPawnBO.houseType = "产前安置房";
      } else if (yl == "JJSYF") {
        this.orderPawnBO.houseType = "经济适用房";
      } else if (yl == "ZCXZF") {
        this.orderPawnBO.houseType = "政策性住房";
      } else if (yl == "XJSPF") {
        this.orderPawnBO.houseType = "限价商品房";
      } else if (yl == "GY") {
        this.orderPawnBO.houseType = "公寓";
      } else if (yl == "SP") {
        this.orderPawnBO.houseType = "商铺";
      } else if (yl == "BGL") {
        this.orderPawnBO.houseType = "办公楼";
      } else if (yl == "ZJF") {
        this.orderPawnBO.houseType = "自建房";
      } else if (yl == "LXF") {
        this.orderPawnBO.houseType = "两限房";
      } else if (yl == "XGF") {
        this.orderPawnBO.houseType = "新工房";
      } else if (yl == "BS") {
        this.orderPawnBO.houseType = "别墅";
      } else if (yl == "FLF") {
        this.orderPawnBO.houseType = "福利房";
      } else if (yl == "LL") {
        this.orderPawnBO.houseType = "里弄";
      } else if (yl == "BZXZF") {
        this.orderPawnBO.houseType = "保障性住房";
      } else if (yl == "SZLY") {
        this.orderPawnBO.houseType = "商住两用";
      } else if (yl == "QT") {
        this.orderPawnBO.houseType = "其他";
      }
    },
    // 翻译土地性质
    fyhouseLandStatus() {
      let yl = this.orderPawnBO.houseLandStatus;
      if (yl == "出让") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseLandStatus = "CR";
      } else if (yl == "划拨") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseLandStatus = "HB";
      } else if (yl == "转让") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseLandStatus = "ZR";
      } else if (yl == "其他") {
        this.submitTypeInOrderInfoBo.orderPawnBO.houseLandStatus = "QT";
      }

      if (yl == "CR") {
        this.orderPawnBO.houseLandStatus = "出让";
      } else if (yl == "HB") {
        this.orderPawnBO.houseLandStatus = "划拨";
      } else if (yl == "ZR") {
        this.orderPawnBO.houseLandStatus = "转让";
      } else if (yl == "QT") {
        this.orderPawnBO.houseLandStatus = "其他";
      }
    },

    // 提交按钮
    Submitbtn() {
      let bool = false;
      // 拼装提交订单的请求参数
      this.submitTypeInOrderInfoBo.orderBo = { ...this.orderBo };
      this.submitTypeInOrderInfoBo.customersBo = { ...this.customersBo };
      this.submitTypeInOrderInfoBo.orderPawnBO = { ...this.orderPawnBO };
      this.submitTypeInOrderInfoBo.orderAccessoryVos = [
        ...this.orderAccessoryVos,
      ];

      // 判断 信息是否填写完整
      for (const key in this.submitTypeInOrderInfoBo.orderBo) {
        if (this.submitTypeInOrderInfoBo.orderBo[key]) {
          bool = true;
        } else {
          bool = false;
        }
      }
      if (this.submitTypeInOrderInfoBo.customersBo.customerName) {
        bool = true;
      } else {
        bool = false;
      }
      if (this.submitTypeInOrderInfoBo.customersBo.contactPhone) {
        bool = true;
      } else {
        bool = false;
      }
      if (this.submitTypeInOrderInfoBo.customersBo.idCard) {
        bool = true;
      } else {
        bool = false;
      }

      // 判断是否从订单列表进入，有orderId是从订单列表进入
      if (this.orderId) {
        this.submitTypeInOrderInfoBo.orderId = this.orderId;
      }
      // 判断 信息是否完整
      if (bool) {
        // 单位是万元，所以加上4个0
        this.submitTypeInOrderInfoBo.orderBo.applyAmount *= "10000";
        // 翻译性别
        this.fygender();
        // 翻译学历
        this.submitTypeInOrderInfoBo.customersBo.education
          ? this.fyeducation()
          : (this.submitTypeInOrderInfoBo.customersBo.education = null);
        // 翻译婚姻状态
        this.submitTypeInOrderInfoBo.customersBo.maritalState
          ? this.fymaritalState()
          : (this.submitTypeInOrderInfoBo.customersBo.maritalState = null);
        // 翻译居住性质
        this.submitTypeInOrderInfoBo.customersBo.residentialProperties
          ? this.fyresidentialProperties()
          : (this.submitTypeInOrderInfoBo.customersBo.residentialProperties =
              null);
        // 翻译房屋类型
        this.submitTypeInOrderInfoBo.orderPawnBO.houseType
          ? this.fyhouseType()
          : (this.submitTypeInOrderInfoBo.orderPawnBO.houseType = null);
        // 翻译土地性质
        this.submitTypeInOrderInfoBo.orderPawnBO.houseLandStatus
          ? this.fyhouseLandStatus()
          : (this.submitTypeInOrderInfoBo.orderPawnBO.houseLandStatus = null);
        // 提交接口
        cacheTypeInOrderInfo(this.submitTypeInOrderInfoBo).then((res) => {
          if (res.data.code == 0) {
            if (this.orderId) {
              this.$router.push({
                path: "/orderRecord",
                query: {},
              });
              this.$toast("修改成功");
            } else {
              this.$router.push({
                path: "/submittedSuccessfully",
                query: {
                  data: JSON.stringify(this.submitTypeInOrderInfoBo),
                  id: res.data.data,
                },
              });
            }
          } else {
            this.$toast(res.data.msg);
          }
        });
      } else {
        this.$toast("请将必填资料填写完整");
      }
    },
    //截取code
    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      console.log(theRequest.code);
      // code 发送到后台
      getGzhCode({ code: theRequest.code }).then((res) => {
        console.log(res);
      });
    },
  },
  created() {
    this.getUrlCode();
    // 判断是否从订单列表进入
    if (this.$route.query.orderId) {
      this.orderId = this.$route.query.orderId;
      this.orderNumber = this.$route.query.orderNumber;
      typeInOrderInfo({ orderNumber: this.orderNumber }).then((res) => {
        // console.log(res);
        this.orderBo = res.data.data.submitTypeInOrderInfoVO.orderVo;
        this.customersBo = res.data.data.submitTypeInOrderInfoVO.customersVo;
        this.orderPawnBO = res.data.data.submitTypeInOrderInfoVO.orderPawnVo;
        this.fygender();
        this.fyeducation();
        this.fymaritalState();
        this.fyresidentialProperties();
        this.fyhouseType();
        this.fyhouseLandStatus();
        this.orderBo.applyAmount /= "10000";
        let Dto = res.data.data.submitTypeInOrderInfoVO.orderAccessoryDto;

        // 回显图片
        for (let i = 0; i < Dto.length; i++) {
          if (Dto[i].orderAccessoryDtos.length >= 1) {
            for (let j = 0; j < Dto[i].orderAccessoryDtos.length; j++) {
              this.fileList[Dto[i].orderAccessoryDtos[j].accessoryTypeId - 1] =
                [];
              this.fileList[
                Dto[i].orderAccessoryDtos[j].accessoryTypeId - 1
              ].push({
                url: Dto[i].orderAccessoryDtos[j].accessoryUrl,
              });
              Dto[i].orderAccessoryDtos[j].typeName = Dto[i].accessoryTypeName;
              this.orderAccessoryVos.push(Dto[i].orderAccessoryDtos[j]);
            }
          }
        }
      });
    }
    // 判断是否从重新修改  进入
    if (this.$route.query.data) {
      let data = JSON.parse(this.$route.query.data);
      this.orderId = this.$route.query.id;
      this.orderBo = data.orderBo;
      this.customersBo = data.customersBo;
      this.orderPawnBO = data.orderPawnBO;
      this.orderAccessoryVos = data.orderAccessoryVos;
      // 回显图片
      if (this.orderAccessoryVos) {
        for (let i = 0; i < this.orderAccessoryVos.length; i++) {
          this.fileList[this.orderAccessoryVos[i].accessoryTypeId - 1].push({
            url: this.orderAccessoryVos[i].accessoryUrl,
          });
        }
      }

      this.fygender();
      this.fyeducation();
      this.fymaritalState();
      this.fyresidentialProperties();
      this.fyhouseType();
      this.fyhouseLandStatus();
      this.orderBo.applyAmount /= "10000";
    }
    // 获取 影像资料列表
    getTypeInOrderInfoAccessoryFind().then((res) => {
      this.finddata = res.data.data;
    });
    // 获取 客户经理等信息
    let data = JSON.parse(getDate());
    console.log(data);

    this.customersBo.channelId = data.channelId;
    this.customersBo.channelName = data.companyNameShort;
    // this.customersBo.companyNameShort = data.companyNameShort;
    this.customersBo.managerId = data.id;
    this.customersBo.customerManagerName = data.name;
    this.orderBo.productCode = "1";
    this.orderBo.newType = "SCDZ";
    this.orderBo.applyAnnualInterestRate = "16";
  },
};
</script>

<style scoped>
.iconbox {
  display: flex;
  justify-content: space-around;
}
.iconbox {
  margin-bottom: 25px;
}
.iconbox-box {
  width: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  position: relative;
}
.iconbox-xx {
  width: 80%;
  height: 1px;
  background: #bca675;
  position: absolute;
  top: 30px;
}
.app-conter {
  width: 100%;
  margin: auto;
  background-color: #f8f8f8;
}
.main {
  width: 90%;
  margin: auto;
}
.iconbox {
  height: 70px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
}
.titlebox {
  margin-top: 30px;
  display: flex;
}
.title {
  font-size: 16px;
  color: black;
  height: 30px;
  font-weight: bold;
}
.title-back {
  width: 5px;
  height: 18px;
  background: linear-gradient(180deg, #bca675 0%, #a78a48 100%);
  margin-right: 10px;
}
.main {
  background: #f8f8f8;
}
</style>
